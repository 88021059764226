import {HashLink} from "react-router-hash-link";
import {useEffect, useState} from "react";
import {AiOutlineClose, AiOutlineMenu} from "react-icons/ai";
import navbarLogo from "../../assets/navbarLogo.png"

const links = [
    {
        title: "Oferty",
        link: "/#oferty"
    },
    {
        title: "Zbiórki",
        link: "/#zbiorkki"
    },
    {
        title: "Opinie",
        link: "/#opinie"
    },
    {
        title: "FAQ",
        link: "/#faq"
    },
    {
        title: "Kontakt",
        link: "/#kontakt"
    },
]

const Navbar = (props) => {

    const [nav, setNav] = useState(false);
    const [scrolling, setScrolling] = useState(false)

    const handleNav = () => {
        setNav(!nav);
    };

    const handleScroll = () => {
        if (window.scrollY > 60) {
            setScrolling(true);
        } else {
            setScrolling(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }

    }, [])

    const [showText ,setShowText] = useState(false);

    return (
        <div
            className={scrolling ? `shadow-2xl navbar fixed z-[1000] bg-[#FFF] top-0 text-[#fbfbfbb3] w-full h-[100px]` : `fixed navbar z-[1000] top-0 text-[${props?.color}] w-full h-[100px]`}>
            <div className="container mx-auto w-full">
                <div className="flex items-center justify-between h-[100px] lg:hidden">
                    <img src={navbarLogo} alt="" className="h-[80px]"/>
                    <AiOutlineMenu onClick={handleNav} size={30} color={"black"}/>
                </div>

                <div className="flex items-center justify-between">
                    <img src={navbarLogo} alt="" className="h-[80px] hidden lg:block"/>
                    <div className="flex items-center justify-center h-[100px] hidden lg:flex gap-[50px]">
                        {
                            links.map((link) => (
                                <HashLink to={link?.link}>
                                    <p className="text-[16px] font-poppins font-medium leading-[137%] text-[#10184180] hover:font-semibold hover:text-[#1364EE]">{link?.title}</p>
                                </HashLink>
                            ))
                        }
                    </div>
                    <div className="relative">
                        <button className="hidden lg:flex py-[21px] bg-[#1BADFF] rounded-[33px] px-[44px] items-center" onClick={()=>setShowText(!showText)}>
                            <p className="mr-[10px] text-[16px] font-poppins font-semibold text-[#FFF]">Profil</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                                <path d="M4.71074 5.28189C4.3194 5.67729 3.6806 5.67729 3.28926 5.28189L0.489827 2.45345C-0.135134 1.82201 0.312149 0.75 1.20057 0.75L6.79943 0.750001C7.68785 0.750001 8.13513 1.82201 7.51017 2.45345L4.71074 5.28189Z" fill="white"/>
                            </svg>
                        </button>
                        {
                            showText && (
                                <div className="absolute bg-[#FFF] shadow-2xl text-[#000] mt-[20px] p-[40px] w-[600px] -right-[200px]">
                                    <p>Przykładowy tekst</p>
                                </div>
                            )
                        }
                    </div>

                </div>
            </div>

            {
                nav && (
                    <div className="fixed top-0 left-0 w-full h-full bg-[#FFF] py-[60px]">
                        <div className="container mx-auto h-full w-full">

                            <div className="flex items-center justify-between ">
                                <HashLink to="/#">
                                    <img src={navbarLogo} alt="Logo" className="h-[80px]"/>
                                </HashLink>
                                <AiOutlineClose size={30} className="text-[#000] cursor-pointer"
                                                onClick={() => setNav(false)}/>
                            </div>
                            <div className="h-full w-full flex items-center justify-center flex-col gap-[50px]">
                                {links.map((link) => (
                                    <HashLink to={link?.link}>
                                        <p className={`text-[16px] text-[#000] font-poppins font-medium leading-[137%] hover:text-[#1364EE] hover:font-semibold `}>
                                            {link.title}
                                        </p>
                                    </HashLink>
                                ))}

                                <HashLink to="/#kontakt">
                                    <button className=" py-[21px] bg-[#1BADFF] rounded-[33px] px-[44px] flex items-center">
                                        <p className="mr-[10px] text-[16px] font-poppins font-semibold text-[#FFF]">Profil</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                                            <path d="M4.71074 5.28189C4.3194 5.67729 3.6806 5.67729 3.28926 5.28189L0.489827 2.45345C-0.135134 1.82201 0.312149 0.75 1.20057 0.75L6.79943 0.750001C7.68785 0.750001 8.13513 1.82201 7.51017 2.45345L4.71074 5.28189Z" fill="white"/>
                                        </svg>
                                    </button>
                                </HashLink>
                            </div>

                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default Navbar;