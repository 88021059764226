import logoImage from "../../assets/navbarLogo.png"
import {HashLink} from "react-router-hash-link";
const Footer = () => {
    return (
        <div className="bg-[#1BADFF]">
            <div className="container mx-auto pb-[160px] pt-[300px] z-[-20]">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-[80px]">
                    <div className="xl:col-span-2 flex items-center justify-center md:justify-start">
                        <img src={logoImage} alt="Logo" className="w-[300px] object-cover"/>
                    </div>
                    <div className="flex flex-col">
                        <h1 className="text-[#FFF] font-poppins text-[18px] font-semibold text-center md:text-left">Psiotel</h1>
                        <div className="flex flex-col gap-[20px] mt-[40px] text-center md:text-left">
                            <HashLink to="/#">
                                <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">Strona głowna</p>
                            </HashLink>
                            <HashLink to="/#o-nas">
                                <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">O nas</p>
                            </HashLink>
                            <HashLink to="/#faq">
                                <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">FAQ</p>
                            </HashLink>
                            <HashLink to="/#opinie">
                                <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">Opinie</p>
                            </HashLink>
                            <HashLink to="/#kontakt">
                                <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">Kontakt</p>
                            </HashLink>
                        </div>
                    </div>

                    <div className="flex flex-col text-center md:text-left">
                        <h1 className="text-[#FFF] font-poppins text-[18px] font-semibold ">Akcje charytatywne</h1>
                        <div className="flex flex-col gap-[20px] mt-[40px]">
                            <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">Aktualna akcja</p>
                            <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">Odbyte zbiórki</p>
                            <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">Siepomaga.pl</p>
                        </div>
                    </div>

                    <div className="flex flex-col text-center md:text-left">
                        <h1 className="text-[#FFF] font-poppins text-[18px] font-semibold">Ważne linki</h1>
                        <div className="flex flex-col gap-[20px] mt-[40px]">
                            <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">Regulamin</p>
                            <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">Polityka prywatności</p>
                            <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">Terms of service</p>
                        </div>
                    </div>

                    <div className="flex flex-col text-center md:text-left lg:col-start-2 xl:col-start-auto">
                        <h1 className="text-[#FFF] font-poppins text-[18px] font-semibold">Socialmedia</h1>
                        <div className="flex flex-col gap-[20px] mt-[40px]">
                            <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">Facebook</p>
                            <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">Instagram</p>
                            <p className="text-[16px] font-medium text-[#FFFFFF80] font-poppins hover:text-[#FFF]">TikTok</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;