import Form from "./form/Form";
import Info from "./info/Info";

const Contact = () => {
    return(
        <section id="kontakt">
            <div className="relative -bottom-[300px] container mx-auto pb-[80px]">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-[50px] lg:gap-[200px]">
                    <div className="lg:order-first z-[500]">
                        <Form/>
                    </div>
                    <div className="order-first">
                        <Info/>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Contact;