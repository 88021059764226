import avatarImage from "../../assets/avatarImage.png"
import Card from "./card/Card";

const Opinions = () => {

    const texts = [
        {
            author: "Adam Ciechorski",
            rank: "Klient",
            description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus. Integer rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus.  Integer rhoncus pretium rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
        },
        {
            author: "Adam Ciechorski",
            rank: "Klient",
            description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus. Integer rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus.  Integer rhoncus pretium rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
        },
        {
            author: "Adam Ciechorski",
            rank: "Klient",
            description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus. Integer rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus.  Integer rhoncus pretium rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
        },
        {
            author: "Adam Ciechorski",
            rank: "Klient",
            description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus. Integer rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus.  Integer rhoncus pretium rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
        },
    ]

    return(
        <section id="opinie">
            <div className="container mx-auto py-[160px]">
                <h1 className="text-[40px] text-[#101841] font-pusia-bold leading-[125%] text-center lg:text-left">Co mówią o nas?</h1>
                <p className="mt-[40px] font-poppins text-[18px] font-medium text-[#10184180] max-w-[520px] text-center lg:text-left mx-auto lg:mx-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus. Orci varius natoque.
                </p>
                <div className="grid grid-cols-1 lg:grid-cols-2 mt-[60px] gap-y-[40px] gap-x-[20px]">
                    {
                        texts.map((opinion, index) => (
                            <Card author={opinion?.author} rank={opinion?.rank} description={opinion?.description} logo={avatarImage}/>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default Opinions;