const Form = () => {
    return(
        <div className="p-[60px] shadow-2xl rounded-[26px] flex flex-col gap-[20px] bg-[#FFF]">

            <input type="text" placeholder="Imię i nazwisko" className="border border-[#1BADFF] rounded-[15px] p-[26px]"/>
            <input type="email" placeholder="E-mail" className="border border-[#1BADFF] rounded-[15px] p-[26px]"/>
            <input type="text" placeholder="Temat" className="border border-[#1BADFF] rounded-[15px] p-[26px]"/>
            <textarea type="text" placeholder="Wiadomość" className="border border-[#1BADFF] rounded-[15px] p-[26px] h-[200px] text-start resize-none"></textarea>

            <div className="flex items-start">
                <input type="checkbox"/>
                <p className="ml-[10px] text-[12px] font-poppins font-medium text-[#10184180]">
                    Wypełniając i przesyłając formularz niniejszym wyraża Pan/Pani zgodę na przetwarzanie swoich danych osobowych przez Psiotel.pl jako administratora danych osobowych oraz akceptuje Pan/Pani regulamin zawarty na stronie www.
                </p>
            </div>

            <div className="pt-[20px] flex items-center justify-center lg:justify-end">
                <button className="px-[47px] py-[27px] rounded-[33px] bg-[#1BADFF] text-[#FFF] font-poppins font-semibold text-[16px]">
                    Wyślij wiadomość
                </button>
            </div>
        </div>
    )
}

export default Form;