import Left from "./left/Left";
import Right from "./right/Right";

const Header = () => {
    return (
        <div className="bg-[#ECF8FF] py-[200px] relative overflow-hidden">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 xl:grid-cols-2">
                    <Left/>
                    <Right/>
                </div>
            </div>
            <div className="absolute -bottom-[50px] right-0 hidden xl:block">
                <svg xmlns="http://www.w3.org/2000/svg" width="908" height="957" viewBox="0 0 908 957" fill="none">
                    <path d="M907.086 840C729.235 1024.5 326.553 975.908 142.058 798.058C-42.4368 620.207 -47.8231 326.468 130.028 141.973C307.878 -42.5221 601.617 -47.9084 786.112 129.942C970.607 307.793 1084.94 655.505 907.086 840Z" fill="url(#paint0_linear_141_1406)" fill-opacity="0.4"/>
                    <defs>
                        <linearGradient id="paint0_linear_141_1406" x1="827.831" y1="170.158" x2="183.777" y2="838.274" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#91D7FF"/>
                            <stop offset="1" stop-color="#91D7FF" stop-opacity="0"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </div>

    )
}

export default Header;