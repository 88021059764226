const Banner = () => {
    return(
        <div className="container mx-auto ">
            <div className="bg-[#1BADFF] py-[40px] lg:py-[85px] rounded-[47px] relative bottom-[100px] z-[600] flex flex-col items-center justify-center mt-[50px] lg:mt-0">
                <h1 className="text-center text-[#FFFFFF] font-pusia-bold text-[30px] lg:text-[40px] leading-[125%]">Co mówią liczby</h1>
                <p className="text-center font-poppins font-medium text-[14px] lg:text-[18px] text-[#FFFFFF80] max-w-[520px] mx-auto mt-[40px]">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus. Orci varius natoque.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-[60px] gap-[50px] lg:gap-[100px]">
                    <div className="flex flex-col items-center justify-center lg:max-w-[200px]">
                        <h1 className="text-[#FFF] font-pusia-bold text-[40px] lg:text-[70px] leading-[125%]">120</h1>
                        <p className="text-center text-[#FFFFFF80] text-[12px] lg:text-[16px] font-poppins mt-[20px]">Dostępnych hoteli dla Twoich pociech</p>
                    </div>
                    <div className="flex flex-col items-center justify-center lg:max-w-[200px]">
                        <h1 className="text-[#FFF] font-pusia-bold text-[40px] lg:text-[70px] leading-[125%]">240</h1>
                        <p className="text-center text-[#FFFFFF80] text-[12px] lg:text-[16px] font-poppins mt-[20px]">Osób, które zaopiekują się Twoim zwierzakiem</p>
                    </div>
                    <div className="flex flex-col items-center justify-center lg:max-w-[200px]">
                        <h1 className="text-[#FFF] font-pusia-bold text-[40px] lg:text-[70px] leading-[125%]">23</h1>
                        <p className="text-center text-[#FFFFFF80] text-[12px] lg:text-[16px] font-poppins mt-[20px]">Odbyte zbiórki charytatywne</p>
                    </div>
                    <div className="flex flex-col items-center justify-center lg:max-w-[200px]">
                        <h1 className="text-[#FFF] font-pusia-bold text-[40px] lg:text-[70px] leading-[125%]">135</h1>
                        <p className="text-center text-[#FFFFFF80] text-[12px] lg:text-[16px] font-poppins mt-[20px]">Pozytywnych opinii na temat naszej aplikacji</p>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default Banner;