import Card from "./card/Card";

const Offer = () => {
    return(
        <section id="oferty">
            <div className="bg-[#FFF] my-[180px]">
                <div className="container mx-auto">
                    <h1 className="text-[40px] text-[#101841] font-pusia-bold leading-[125%] text-center"> Polecane oferty</h1>
                    <p className="text-center mt-[40px] max-w-[650px] mx-auto font-poppins text-[#10184180] text-[18px]">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus. Orci varius natoque.</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[83px] my-[60px]">
                        <Card title="Pet hotel" rating="4.7" desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus."/>
                        <Card title="Pet hotel" rating="4.7" desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus."/>
                        <Card title="Pet hotel" rating="4.7" desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus."/>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default Offer;