const Info = () => {
    return(
        <div className="flex flex-col text-center lg:text-left">
            <h1 className="text-[40px] font-pusia-bold text-[#101841] leading-[108%]">Skontaktuj się z nami</h1>
            <p className="font-poppins font-medium mt-[40px] max-w-[540px] text-[#10184180] text-[18px] mx-auto lg:mx-0">
                Praesent porta risus ac pharetra porttitor. Cras varius ex velit, sit amet volutpat ante lacinia sit amet.
            </p>

            <div className="flex flex-col gap-[35px] mt-[65px] mx-auto lg:mx-0">

                <div className="flex items-center justify-center lg:justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clip-path="url(#clip0_641_251)">
                            <path d="M19.4293 14.1329L15.0543 12.2579C14.8674 12.1783 14.6597 12.1615 14.4624 12.2101C14.2652 12.2587 14.089 12.3701 13.9605 12.5275L12.023 14.8947C8.98232 13.461 6.53524 11.0139 5.10156 7.97318L7.46875 6.03568C7.62644 5.90743 7.73804 5.7313 7.78668 5.53394C7.83531 5.33659 7.81832 5.12877 7.73828 4.94193L5.86328 0.566931C5.77543 0.365528 5.62007 0.201089 5.42397 0.10197C5.22787 0.00284967 5.00332 -0.0247385 4.78906 0.0239622L0.726562 0.961462C0.519988 1.00916 0.335682 1.12548 0.203725 1.29142C0.0717677 1.45736 -4.75863e-05 1.66312 2.36571e-08 1.87513C2.36571e-08 11.8947 8.12109 20.0001 18.125 20.0001C18.3371 20.0003 18.5429 19.9285 18.709 19.7965C18.875 19.6646 18.9913 19.4802 19.0391 19.2736L19.9766 15.2111C20.025 14.9958 19.9968 14.7703 19.8969 14.5736C19.797 14.3768 19.6317 14.221 19.4293 14.1329Z" fill="#1BADFF"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_641_251">
                                <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <p className="ml-[25px] text-[15px] font-poppins font-medium text-[#212E33E5]">
                        +48 123 123 321
                    </p>
                </div>

                <div className="flex items-center justify-center lg:justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                        <path d="M19.6211 5.01917C19.7734 4.89646 20 5.01125 20 5.20521V13.3C20 14.349 19.1602 15.2 18.125 15.2H1.875C0.839844 15.2 0 14.349 0 13.3V5.20917C0 5.01125 0.222656 4.90042 0.378906 5.02312C1.25391 5.71187 2.41406 6.58667 6.39844 9.51979C7.22266 10.1294 8.61328 11.4119 10 11.404C11.3945 11.4158 12.8125 10.1056 13.6055 9.51979C17.5898 6.58667 18.7461 5.70792 19.6211 5.01917ZM10 10.1333C10.9062 10.1492 12.2109 8.9775 12.8672 8.49458C18.0508 4.68271 18.4453 4.35021 19.6406 3.40021C19.8672 3.22208 20 2.945 20 2.65208V1.9C20 0.851042 19.1602 0 18.125 0H1.875C0.839844 0 0 0.851042 0 1.9V2.65208C0 2.945 0.132812 3.21812 0.359375 3.40021C1.55469 4.34625 1.94922 4.68271 7.13281 8.49458C7.78906 8.9775 9.09375 10.1492 10 10.1333Z" fill="#1BADFF"/>
                    </svg>
                    <p className="ml-[25px] text-[15px] font-poppins font-medium text-[#212E33E5]">
                        kontakt@jarkop.pl
                    </p>
                </div>

                <div className="flex items-center justify-center lg:justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="27" viewBox="0 0 20 27" fill="none">
                        <g clip-path="url(#clip0_641_258)">
                            <path d="M11.2344 23.6609C13.9062 20.6437 20 13.3311 20 9.22355C20 4.2419 15.5208 0.200195 10 0.200195C4.47917 0.200195 0 4.2419 0 9.22355C0 13.3311 6.09375 20.6437 8.76562 23.6609C9.40625 24.38 10.5938 24.38 11.2344 23.6609ZM10 6.21576C10.8841 6.21576 11.7319 6.53265 12.357 7.09672C12.9821 7.66079 13.3333 8.42583 13.3333 9.22355C13.3333 10.0213 12.9821 10.7863 12.357 11.3504C11.7319 11.9144 10.8841 12.2313 10 12.2313C9.11594 12.2313 8.2681 11.9144 7.64298 11.3504C7.01786 10.7863 6.66667 10.0213 6.66667 9.22355C6.66667 8.42583 7.01786 7.66079 7.64298 7.09672C8.2681 6.53265 9.11594 6.21576 10 6.21576Z" fill="#1BADFF"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_641_258">
                                <rect width="20" height="26.6667" fill="white" transform="translate(0 0.200195)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <p className="ml-[25px] text-[15px] font-poppins font-medium text-[#212E33E5]">
                        Prusinowo,
                        5662-035 Kórnik
                    </p>
                </div>

            </div>


        </div>
    )

}

export default Info;