import aboutUsDog from '../../assets/aboutUsDog.png'

const AboutUs = () => {
    return(
        <section id="o-nas">
            <div className="relative bg-[#EDF8FF] ">
                <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 py-[200px]">
                    <div className="h-full w-full flex items-center justify-center">
                        <div className="bg-[#1BADFF] rounded-[560px] w-[250px] h-[250px] sm:w-[350px] sm:h-[350px] md:w-[450px] md:h-[450px] relative mx-auto">
                            <img src={aboutUsDog} className="absolute bottom-[0px]"/>
                        </div>
                    </div>

                    <div>
                        <h1 className="text-[#101841] font-pusia-bold text-[40px] leading-[126%] text-center lg:text-left">Kim jesteśmy?</h1>
                        <p className="text-[18px] font-poppins font-medium text-[#10184180] mt-[40px]  text-center lg:text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus pretium rhoncus. Orci varius natoque.</p>
                        <p className="text-[16px] font-poppins text-[#10184180] mt-[60px] leading-[152%]  text-center lg:text-left">Witaj w naszej aplikacji do zarządzania salonem kosmetycznym! Dzięki naszemu narzędziu będziesz mógł efektywnie zarządzać swoim biznesem kosmetycznym, zarówno jeśli chodzi o harmonogramowanie wizyt, rezerwację terminów, jak i zarządzanie zespołem pracowników.</p>
                        <p className="text-[16px] font-poppins text-[#10184180] my-[40px] leading-[152%]  text-center lg:text-left">Witaj w naszej aplikacji do zarządzania salonem kosmetycznym! Dzięki naszemu narzędziu będziesz mógł efektywnie zarządzać swoim biznesem kosmetycznym, zarówno jeśli chodzi o harmonogramowanie wizyt, rezerwację terminów, jak i zarządzanie zespołem pracowników.</p>
                        <div className="flex flex-col lg:flex-row items-center w-full ">
                            <button className="flex items-center py-[25px] px-[41px] bg-[#1BADFF] rounded-[31px] w-full lg:w-auto justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                                    <path d="M13.125 11.2771V1.43335C13.125 1.0437 12.8115 0.730225 12.4219 0.730225H2.8125C1.25977 0.730225 0 1.98999 0 3.54272V12.9177C0 14.4705 1.25977 15.7302 2.8125 15.7302H12.4219C12.8115 15.7302 13.125 15.4167 13.125 15.0271V14.5583C13.125 14.3386 13.0225 14.1394 12.8643 14.0105C12.7412 13.5593 12.7412 12.2732 12.8643 11.822C13.0225 11.696 13.125 11.4968 13.125 11.2771ZM3.75 4.65601C3.75 4.55933 3.8291 4.48022 3.92578 4.48022H10.1367C10.2334 4.48022 10.3125 4.55933 10.3125 4.65601V5.24194C10.3125 5.33862 10.2334 5.41772 10.1367 5.41772H3.92578C3.8291 5.41772 3.75 5.33862 3.75 5.24194V4.65601ZM3.75 6.53101C3.75 6.43433 3.8291 6.35522 3.92578 6.35522H10.1367C10.2334 6.35522 10.3125 6.43433 10.3125 6.53101V7.11694C10.3125 7.21362 10.2334 7.29272 10.1367 7.29272H3.92578C3.8291 7.29272 3.75 7.21362 3.75 7.11694V6.53101ZM11.1738 13.8552H2.8125C2.29395 13.8552 1.875 13.4363 1.875 12.9177C1.875 12.4021 2.29688 11.9802 2.8125 11.9802H11.1738C11.1182 12.4812 11.1182 13.3542 11.1738 13.8552Z" fill="white"/>
                                </svg>
                                <p className="ml-[15px] text-[16px] text-[#FFF] font-semibold font-poppins">
                                    Zabookuj hotel
                                </p>
                            </button>
                            <p className="ml-[40px] text-[#1BADFFB2] text-[16px] font-poppins font-semibold cursor-pointer w-full lg:w-auto mt-[30px] lg:mt-0 text-center lg:text-left">Dowiedz się więcej</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )

}

export default AboutUs;