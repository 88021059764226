import cardImage from "../../../assets/offerCardImage.png"
const Card = (props) => {
    return(
        <div>
            <div className="p-[5px] border border-[#1BADFF80] rounded-[30px]">
                <img src={cardImage} className="w-full h-full"/>
            </div>
            <div className="mt-[20px]">
                <div className="flex items-center">
                    <div className="flex items-center">
                        <p className="text-[#101841] text-[18px[ font-poppins mr-[20px] font-semibold leading-[162%]">{props.title}</p>
                    </div>
                    <div className="flex items-center">
                        <p className="text-[#0C1B33B2] mr-[5px] font-poppins text-[16px] font-semibold">{props.rating}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 35 34" fill="none">
                            <path d="M16.5489 12.1407C16.8483 11.2194 18.1517 11.2194 18.4511 12.1407L19.1839 14.396C19.3177 14.808 19.7017 15.087 20.1349 15.087L22.5063 15.087C23.475 15.087 23.8778 16.3266 23.0941 16.896L21.1756 18.2899C20.8251 18.5445 20.6784 18.9959 20.8123 19.4079L21.5451 21.6632C21.8445 22.5845 20.79 23.3507 20.0063 22.7813L18.0878 21.3874C17.7373 21.1327 17.2627 21.1327 16.9122 21.3874L14.9937 22.7813C14.21 23.3507 13.1555 22.5845 13.4549 21.6632L14.1877 19.4079C14.3216 18.9959 14.1749 18.5445 13.8244 18.2899L11.9059 16.896C11.1222 16.3266 11.525 15.087 12.4937 15.087L14.8651 15.087C15.2983 15.087 15.6823 14.808 15.8161 14.396L16.5489 12.1407Z" fill="#FFB930"/>
                            <g filter="url(#filter0_f_141_1576)">
                                <path d="M16.5489 12.1407C16.8483 11.2194 18.1517 11.2194 18.4511 12.1407L19.1839 14.396C19.3177 14.808 19.7017 15.087 20.1349 15.087L22.5063 15.087C23.475 15.087 23.8778 16.3266 23.0941 16.896L21.1756 18.2899C20.8251 18.5445 20.6784 18.9959 20.8123 19.4079L21.5451 21.6632C21.8445 22.5845 20.79 23.3507 20.0063 22.7813L18.0878 21.3874C17.7373 21.1327 17.2627 21.1327 16.9122 21.3874L14.9937 22.7813C14.21 23.3507 13.1555 22.5845 13.4549 21.6632L14.1877 19.4079C14.3216 18.9959 14.1749 18.5445 13.8244 18.2899L11.9059 16.896C11.1222 16.3266 11.525 15.087 12.4937 15.087L14.8651 15.087C15.2983 15.087 15.6823 14.808 15.8161 14.396L16.5489 12.1407Z" fill="#FFB930" fill-opacity="0.5"/>
                            </g>
                            <defs>
                                <filter id="filter0_f_141_1576" x="0.492188" y="0.449707" width="34.0156" height="33.5271" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                    <feGaussianBlur stdDeviation="5.5" result="effect1_foregroundBlur_141_1576"/>
                                </filter>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div>
                    <p className="text-[16px] font-medium leading-[162%] text-[#10184180] font-poppins">{props.desc}</p>
                </div>
            </div>
        </div>
    )
}
export default Card;