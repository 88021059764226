import dogOneImage from '../../../assets/dogOneImage.png'
import dogTwoImage from '../../../assets/dogTwoImage.png'
const Right = () => {
    return(
        <div className="relative z-[500] h-full hidden xl:block">
            <img src={dogOneImage} alt="w" className="absolute -bottom-[200px] right-[200px] max-h-full" />
            <img src={dogTwoImage} alt="w" className="absolute -bottom-[240px] -right-[200px] " />
        </div>
    )
}

export default Right;