import Navbar from "./components/navbar/Navbar";

import Header from "./components/header/Header";
import Offer from "./components/offer/Offer";
import AboutUs from "./components/aboutUs/aboutUs";
import Banner from "./components/banner/Banner";
import Opinions from "./components/opinions/Opinions";
import Footer from "./components/footer/Footer";
import Contact from "./components/contact/Contact";
import {BrowserRouter, Route, Routes} from "react-router-dom";

function App() {
  return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={[<Navbar/> , <Header/> , <Banner/> , <Offer/> , <AboutUs/> , <Opinions/>, <Contact/>,<Footer/>]}></Route>
            </Routes>
        </BrowserRouter>
  );
}

export default App;
