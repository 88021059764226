import {useState} from "react";

const Left = () => {

    const inputs = [
        <input type="text" placeholder="Lokalizacja" className="lg:hidden text-center bg-transparent h-[64px] p-[5px] text-[16px] text-[#10184180] font-medium font-poppins border border-[#1BADFF80] rounded-[100px] w-full mt-[20px]"/>,
        <input type="number" placeholder="Ilość zwierząt" className="lg:hidden text-center bg-transparent h-[64px] p-[5px] text-[16px] text-[#10184180] font-medium font-poppins border border-[#1BADFF80] rounded-[100px] w-full mt-[20px]"/>,
        <input type="date" placeholder="Od - Do" className="lg:hidden text-center bg-transparent h-[64px] p-[5px] text-[16px] text-[#10184180] font-medium font-poppins border border-[#1BADFF80] rounded-[100px] w-full mt-[20px]"/>,
    ]

    const handleClick = (index) => {
        setShowInput(true);
        setInput(index);
    }

    const [showInput , setShowInput] = useState(false);
    const [input , setInput] = useState(0);

    return(
        <div className="flex flex-col text-center lg:text-left">
            <div className="px-[25px] py-[13px] bg-[#1BADFF1A] w-[256px] rounded-[31px] mx-auto lg:mx-0">
                <p className="text-[14px] text-[#1BADFF] text-center lg:text">Twoja aplikacja dla zwierząt!</p>
            </div>
            <h1 className="text-[50px] lg:text-[68px] text-[#101841] font-pusia-bold my-[50px]">Znajdź wspaniałe miejsce dla Twojego zwierzaka!</h1>
            <p className="text-[15px] lg:text-[18px] text-[#10184180] font-medium font-poppins leading-[162%]">Lorem ipsum dolor <span className="font-semibold text-[#101841B2]">sit amet</span>, consectetur adipiscing elit. Integer rhoncus pretium rhoncus. <span className="font-semibold text-[#101841B2]">Orci varius natoque</span>.</p>


            <div className="">
                <div className="mt-[80px] rounded-[100px] border border-[#1BADFF80] flex flex-col lg:flex-row justify-between items-center h-[64px] p-[5px]">

                    <div className="flex items-center justify-center lg:ml-[30px] w-full mt-[16px] lg:mt-0">

                        <div className="flex w-auto lg:w-full">
                            <div className="flex items-center justify-center w-full  cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none" onClick={()=> handleClick(0)}>
                                    <path d="M6.44324 14.6629C7.97564 12.7772 11.4706 8.20679 11.4706 5.63959C11.4706 2.52607 8.90165 0 5.73529 0C2.56893 0 0 2.52607 0 5.63959C0 8.20679 3.49495 12.7772 5.02734 14.6629C5.39476 15.1124 6.07583 15.1124 6.44324 14.6629ZM5.73529 3.75973C6.24233 3.75973 6.72859 3.95779 7.08712 4.31033C7.44564 4.66287 7.64706 5.14102 7.64706 5.63959C7.64706 6.13817 7.44564 6.61632 7.08712 6.96886C6.72859 7.3214 6.24233 7.51946 5.73529 7.51946C5.22826 7.51946 4.742 7.3214 4.38347 6.96886C4.02495 6.61632 3.82353 6.13817 3.82353 5.63959C3.82353 5.14102 4.02495 4.66287 4.38347 4.31033C4.742 3.95779 5.22826 3.75973 5.73529 3.75973Z" fill="#101841" fill-opacity="0.7"/>
                                </svg>
                                <p className="text-[16px] text-[#10184180] font-medium font-poppins ml-[15px] hidden lg:block">Lokalizacja</p>
                            </div>

                            <div className="flex items-center ml-[45px] w-full cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none" onClick={()=> handleClick(1)}>
                                    <path d="M7.08983 2.72106C7.50796 3.9779 7.08106 5.24645 6.13661 5.557C5.19217 5.86754 4.08691 5.09997 3.66878 3.84313C3.25065 2.58629 3.67755 1.31774 4.62199 1.00719C5.56644 0.696646 6.6717 1.46422 7.08983 2.72106ZM3.40269 5.81774C3.95533 6.76696 3.82082 7.87145 3.10445 8.28161C2.38807 8.69176 1.35884 8.25524 0.809128 7.30602C0.25942 6.3568 0.388075 5.25231 1.10445 4.84215C1.82082 4.432 2.85006 4.86852 3.39977 5.81774H3.40269ZM2.49041 11.7533C4.02258 7.61364 6.7448 6.56188 7.9524 6.56188C9.16 6.56188 11.8822 7.61364 13.4144 11.7533C13.5197 12.0375 13.5664 12.3422 13.5664 12.6468V12.6937C13.5664 13.4496 12.9553 14.0619 12.2009 14.0619C11.8647 14.0619 11.5314 14.0209 11.2068 13.9388L8.63369 13.2943C8.18632 13.183 7.71848 13.183 7.27112 13.2943L4.69802 13.9388C4.37346 14.0209 4.04012 14.0619 3.70386 14.0619C2.94948 14.0619 2.33837 13.4496 2.33837 12.6937V12.6468C2.33837 12.3422 2.38515 12.0375 2.49041 11.7533ZM12.8004 8.28161C12.084 7.87145 11.9495 6.76696 12.5021 5.81774C13.0547 4.86852 14.0811 4.432 14.7974 4.84215C15.5138 5.25231 15.6483 6.3568 15.0957 7.30602C14.543 8.25524 13.5167 8.69176 12.8004 8.28161ZM9.53427 5.557C8.58983 5.24645 8.16293 3.9779 8.58106 2.72106C8.99919 1.46422 10.1044 0.696646 11.0489 1.00719C11.9933 1.31774 12.4202 2.58629 12.0021 3.84313C11.584 5.09997 10.4787 5.86754 9.53427 5.557Z" fill="#101841" fill-opacity="0.7"/>
                                </svg>
                                <p className="text-[16px] text-[#10184180] font-medium font-poppins ml-[15px] hidden lg:block">Ilość zwierząt</p>
                            </div>

                            <div className="flex items-center ml-[45px] w-full  cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none" onClick={()=> handleClick(2)}>
                                    <path d="M0.4375 13.5938C0.4375 14.3701 1.06738 15 1.84375 15H12.1562C12.9326 15 13.5625 14.3701 13.5625 13.5938V5.625H0.4375V13.5938ZM9.8125 7.85156C9.8125 7.6582 9.9707 7.5 10.1641 7.5H11.3359C11.5293 7.5 11.6875 7.6582 11.6875 7.85156V9.02344C11.6875 9.2168 11.5293 9.375 11.3359 9.375H10.1641C9.9707 9.375 9.8125 9.2168 9.8125 9.02344V7.85156ZM9.8125 11.6016C9.8125 11.4082 9.9707 11.25 10.1641 11.25H11.3359C11.5293 11.25 11.6875 11.4082 11.6875 11.6016V12.7734C11.6875 12.9668 11.5293 13.125 11.3359 13.125H10.1641C9.9707 13.125 9.8125 12.9668 9.8125 12.7734V11.6016ZM6.0625 7.85156C6.0625 7.6582 6.2207 7.5 6.41406 7.5H7.58594C7.7793 7.5 7.9375 7.6582 7.9375 7.85156V9.02344C7.9375 9.2168 7.7793 9.375 7.58594 9.375H6.41406C6.2207 9.375 6.0625 9.2168 6.0625 9.02344V7.85156ZM6.0625 11.6016C6.0625 11.4082 6.2207 11.25 6.41406 11.25H7.58594C7.7793 11.25 7.9375 11.4082 7.9375 11.6016V12.7734C7.9375 12.9668 7.7793 13.125 7.58594 13.125H6.41406C6.2207 13.125 6.0625 12.9668 6.0625 12.7734V11.6016ZM2.3125 7.85156C2.3125 7.6582 2.4707 7.5 2.66406 7.5H3.83594C4.0293 7.5 4.1875 7.6582 4.1875 7.85156V9.02344C4.1875 9.2168 4.0293 9.375 3.83594 9.375H2.66406C2.4707 9.375 2.3125 9.2168 2.3125 9.02344V7.85156ZM2.3125 11.6016C2.3125 11.4082 2.4707 11.25 2.66406 11.25H3.83594C4.0293 11.25 4.1875 11.4082 4.1875 11.6016V12.7734C4.1875 12.9668 4.0293 13.125 3.83594 13.125H2.66406C2.4707 13.125 2.3125 12.9668 2.3125 12.7734V11.6016ZM12.1562 1.875H10.75V0.46875C10.75 0.210938 10.5391 0 10.2812 0H9.34375C9.08594 0 8.875 0.210938 8.875 0.46875V1.875H5.125V0.46875C5.125 0.210938 4.91406 0 4.65625 0H3.71875C3.46094 0 3.25 0.210938 3.25 0.46875V1.875H1.84375C1.06738 1.875 0.4375 2.50488 0.4375 3.28125V4.6875H13.5625V3.28125C13.5625 2.50488 12.9326 1.875 12.1562 1.875Z" fill="#101841" fill-opacity="0.7"/>
                                </svg>
                                <p className="text-[16px] text-[#10184180] font-medium font-poppins ml-[15px] hidden lg:block">Od - Do</p>
                            </div>
                        </div>

                        <div className="hidden lg:flex justify-end items-center">
                            <button className="text-[16px] text-[#FFF] font-poppins font-semibold bg-[#1BADFF] h-[54px] rounded-[33px] px-[37px]">
                                Szukaj
                            </button>
                        </div>

                    </div>

                </div>

                {
                    showInput && (
                        inputs[input]
                    )
                }

                <div className="lg:hidden flex lg:justify-end lg:items-center lg:h-full mt-[30px] lg:mt-0 w-full lg:w-auto">
                    <button className="bg-[#1BADFF] font-poppins text-[16px] text-[#FFF] font-semibold lg:h-full px-[37px] rounded-[33px] py-[22px] lg:py-0 w-full lg:w-auto mt-[30px] lg:mt-0" onClick={()=>setShowInput(false)}>
                        Szukaj
                    </button>
                </div>
            </div>



        </div>
    )
}

export default Left;
