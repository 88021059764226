const Card = (props) => {
    return(
        <div className="p-[30px] shadow-2xl rounded-[32px]">
            <p className="text-center lg:text-left text-[16px] text-[#10184180] font-poppins font-medium">
                {props?.description}
            </p>
            <div className="flex items-center mt-[40px] justify-center lg:justify-start">
                <div>
                    <img src={props?.logo} alt="Avatar" className="w-[50px] h-[50px] rounded-[100%] object-cover"/>
                </div>
                <div className="flex flex-col ml-[30px]">
                    <h1 className="text-[18px] font-poppins font-semibold text-[#101841]">{props?.author}</h1>
                    <p className="text-[#10184180] font-poppins font-medium text-[16px]">{props?.rank}</p>
                </div>
            </div>
        </div>
    )
}

export default Card;